<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="card-header">
            <h5 class="card-title">Participants</h5>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <div class="btn-group">
              <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
              <p-button type="primary" round outline @click="exportToMailChimp()">Export to MailChimp</p-button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <div>
            <vue-good-table
              :theme="tableTheme"
              ref="participantsGrid"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"


              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',

                perPage: serverParams.perPage,
                setCurrentPage: serverParams.page,

                position: 'bottom',
                perPageDropdown: [10,30,50],
                dropdownAllowAll: true,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                }"
              :sort-options="{
                enabled: true,
                initialSortBy: serverParams.sort
                }">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import * as CONSTS from '../../../consts/constants'

  export default{
    components: {

    },
    computed: {
      ...mapGetters(['userSelectedProduction', 'gridStates', 'tableTheme']),
      productionSelected () {
        return this.$store.getters.userSelectedProduction
      }
    },
    watch: {
      productionSelected (userSelectedProduction, oldValue) {
        console.log('Clients productionSelected')
      // userSelectedProduction has changed (loaded)
        this.loadData()
      }
    },
    mounted () {
      this.updateTableFilters()
    },
    created () {
      let gridState = this.gridStates[this.gridName]

      if (gridState){
        this.serverParams = gridState
      }
      this.loadData()
    },

  // 				Sharer type			Contact	Action


    data () {
      return {
        gridName: 'participantsGrid',
        serverParams: {
          gridName: 'participantsGrid',
          columnFilters: {
          },
          sort: {
            field: 'participant_company', type: 'asc'
          },
          page: 1,
          perPage: 10
        },
        columns: [
          {
            label: 'Edit',
            field: 'actions',
          },
          {
            field: 'participant_company',
            label: 'Participant',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter client',
            }
          },
          {
            field: 'participant_ext_ref',
            label: 'External Reference',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
            }
          },

          {
            field: 'participant_stand_type',
            label: 'Stand Type',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatStandType,
            // type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.stand_types
            }
          },
          {
            field: 'participant_entry_complete',
            label: 'Entry Complete',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatAction,
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.no_yes
            }
          },
          {
            field: 'participant_batch',
            label: 'Batch',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              // filterFn: this.batchFilter,
            }
          },
          {
            field: 'participant_stand',
            label: 'Stand',
            tdClass: 'tablecell',
            thClass: 'tablecell',

            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'showzone',
            label: 'Zone',
            tdClass: 'tablecell',
            thClass: 'tablecell',

            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'participant_hall',
            label: 'Hall',
            tdClass: 'tablecell',
            thClass: 'tablecell',

            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'participant_sector',
            label: 'Sector',
            tdClass: 'tablecell',
            thClass: 'tablecell',

            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'participant_bought',
            label: 'Bought',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatAction,
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.no_yes
            }
          },
          {
            field: 'participant_action_outstanding',
            label: 'Action',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatAction,
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.no_yes
            }
          },
          {
            field: 'participant_prods_complete',
            label: 'Products Complete',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatAction,
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.no_yes
            }
          },
          {
            field: 'participant_brands_complete',
            label: 'Brands Complete',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatAction,
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter',
              filterDropdownItems: CONSTS.strings.no_yes
            }
          },
          {
            field: 'participant_creation_date',
            label: 'Created',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter Sales Person',
              // filterValue: 'Mil'
            }
          },
          {
            field: 'participant_amended_date',
            label: 'Amended',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter Sales Person',
              // filterValue: 'Mil'
            }
          }
        ],
        rows: []
      }
    },
    methods: {

      batchFilter(data, filterString){

          console.log(data, filterString)

      },
      //
      updateTableFilters() {

        for (let i = 0; i < this.columns.length; i++) {
          const col = this.columns[i];
          if (typeof col.filterOptions !== 'undefined') {

            let filterVal = {}

            if ( (filterVal = this.serverParams.columnFilters[ this.columns[i].field ] ) ) {

              let filter = this.columns[i].filterOptions
              filter.filterValue = filterVal
              // seems bonkers having to do this
              let  filterString = JSON.stringify(filter)
              let filterObj = JSON.parse(filterString)

              this.$set(this.columns[i], 'filterOptions', filterObj )
            }
          }
        }
      },
      resetTable: function() {
        this.$refs['participantsGrid'].reset()
        this.serverParams.columnFilters = []
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // table events
      onPageChange(params) {
        this.serverParams.page = params.currentPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onPerPageChange(params) {
        this.serverParams.perPage = params.currentPerPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onSortChange(params) {
        if (params.columnIndex){

        let newSort = {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
        }

        this.serverParams.sort = newSort
        this.$store.dispatch('setGridState', this.serverParams )
        }
      },

      onColumnFilter(params) {
        this.serverParams.columnFilters = params.columnFilters
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // end table events

       searchArray: function(array, key, prop){
          // Optional, but fallback to key['name'] if not selected
          prop = (typeof prop === 'undefined') ? 'name' : prop;

          for (var i=0; i < array.length; i++) {
              if (array[i][prop] === key) {
                  return array[i];
              }
          }
          return null
      },
      formatAction: function(value) {
        let obj = this.searchArray(CONSTS.strings.no_yes, value, 'value')
        if (obj) {
          return obj.text
        }
      },

      formatStandType: function(value) {
        let obj = this.searchArray(CONSTS.strings.stand_types, value, 'value')
        if (obj) {
          return obj.text
        }
      },
      formatExportToAccounts: function(value) {
        let obj = this.searchArray(CONSTS.strings.exportedtosage, value, 'value')
        if (obj) {
          return obj.text
        }
      },
      formatHeat: function(value) {
        let obj = this.searchArray(CONSTS.strings.heat, value, 'value')
        if (obj) {
          return obj.text
        }
      },
      loadData: function () {
        this.$http.get( 'production/participant/' + this.userSelectedProduction )
        .then(response => ( this.rows = response.data ))
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem loading the clients. Please try again.',
            type: 'warning'
          })
        })
      },

      exportToMailChimp: function () {
        this.$http.post( '/email/ping'  )
        .then(response => ( this.rows = response.data ))
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem loading the clients. Please try again.',
            type: 'warning'
          })
        })
      },

      handleEdit (index, row) {
          console.log('routing to ', row.productionId)

          try {
          this.$router.push({ name: 'Participant', params: { id: row.participant_id }})
          }
          catch(e){
            console.log(e)
          }
      }
    }
  }
</script>
<style lang="scss">

 .tablecell {
   font-size: 14px;

 }


</style>
